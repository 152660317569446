import React from 'react';
import PropTypes from 'prop-types';
// import please from 'pleasejs';
// import rand from 'seed-random';
import randomColor from 'randomcolor';
import prompts from '../prompts';
import { promptToWordList } from '../helpers';

class WordList extends React.Component {
  printWords(wordList) {

    // If there are no words on firebase, wordList will be an empty
    // object, so we need to check if that's the case, and set it to
    // an empty array if it is. Or else wordList.map will throw a
    // hissy fit.
    if (!wordList.length) {
      wordList = [];
    }

    return (
      wordList.map((word, index) => {
        const { id, fromName, text } = word;
        const space = index < wordList.length-1 ? ' ' : '';
        const style = {};

        if (fromName) {
          style.color = randomColor({
            seed: fromName,
            luminosity: 'dark',
          });
        }

        return (
          <span key={id} title={fromName} style={style}>{text}{space}</span>
        )
      }
      )
    )
  }

  render() {
    return (
      <span className="word-list">
        { this.printWords(promptToWordList(prompts[0])) }&nbsp;
        { this.printWords(this.props.wordList) }
      </span>
    )
  }
}

WordList.propTypes = {
  wordList: PropTypes.array.isRequired
}

export default WordList;
