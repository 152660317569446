import Rebase from 're-base';
var firebase = require('firebase');

const firebaseConfig = {
  apiKey: "AIzaSyBwdKwLobf9a9xBsufreEpo9thRfm_gSSA",
  authDomain: "multihead-beast.firebaseapp.com",
  databaseURL: "https://multihead-beast.firebaseio.com",
  projectId: "multihead-beast",
  storageBucket: "multihead-beast.appspot.com",
  messagingSenderId: "51349511770",
  appId: "1:51349511770:web:75aded8a62bd1b421b0263"
};

var app = firebase.initializeApp(firebaseConfig);

var base = Rebase.createClass(app.database());

export default base;
