import React from 'react';
import twitterIcon from '../img/twitter-icon.svg';

function LoginPrompt(props) {
  const { numHeads } =  props;
  return (
    <div>
      <p>
        The Marvelous {numHeads}-headed Beast's story trailed off.
        The heads seemed to have run out of words.
      </p>
      <p>
        Perhaps you could help The Marvelous {numHeads}-Headed Beast continue its
        story by pledging your soul to it&hellip;
      </p>
      <button className="btn" onClick={props.authenticate}>
        Pledge your soul
        <img alt="Twitter icon" className="btn__icon" src={ twitterIcon } />
      </button>
    </div>
  )
}

// Intro.propTypes = {
  // numHeads: PropTypes.number.isRequired,
// }

export default LoginPrompt;
