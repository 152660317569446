import React from 'react';
// import please from 'pleasejs';
// import pleaseOpts from '../pleaseOpts';

const BeastWords = (props) => {

  const printWords = (words) => {
    const wordArr = words.split(' ');
    const coloredWords = wordArr.map((word, i) => {
      const space = i < wordArr.length-1 ? ' ' : '';
      // const style = {
        // color: please.make_color(pleaseOpts)
      // }

      return (
        <span key={ i }>{ word }{ space }</span>
      )
    })

    return (
      <q>{ coloredWords }</q>
    )
  }

  return printWords(props.words) 

}

export default BeastWords;
