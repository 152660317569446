// Turn a string into the format wordList expects
export function promptToWordList(prompt) {
  const wordList = prompt.split(' ');

  return wordList.map((word, i) => {
    return {text: word, id: i}
  });
}

// Return a random item from a given array
export function rando(arr) {
  return arr[Math.floor(Math.random()*arr.length)];
}
