import React, { Component } from 'react';
import base from './base';
import { toWords } from 'number-to-words';
import Intro from './components/Intro.js';
import LoginPrompt from './components/LoginPrompt.js';
import WordInput from './components/WordInput.js';
import WordList from './components/WordList.js';
import InsertionPoint from './components/InsertionPoint.js';
import './css/App.css';
import './css/shake-animation.css';
var firebase = require('firebase');

var provider = new firebase.auth.TwitterAuthProvider();

class App extends Component {
  constructor() {
    super();

    this.addWord = this.addWord.bind(this);
    this.inputFocused = this.inputFocused.bind(this);
    this.inputBlurred = this.inputBlurred.bind(this);
    this.updateInputText = this.updateInputText.bind(this);
    this.clearInputText = this.clearInputText.bind(this);
    this.authHandler = this.authHandler.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.unauthenticate = this.unauthenticate.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderLoggedIn = this.renderLoggedIn.bind(this);

    this.state = {
      words: {
        list: [],
        lastWordFrom: null,
        heads: [],
      },
      inputText: '',
      inputFocused: false,
    };
  }

  componentWillMount() {
    this.ref = base.syncState(
      'test/words',
      {
        context: this,
        state: 'words'
      }
    );
  }

  componentDidMount() {
    // base.onAuthentication((user) => {
    //   if(user) {
    //     this.authHandler(null, { user });
    //   }
    // });

    firebase.auth().onAuthStateChanged((user) => {
      console.log(!!user);
      if(user) {
        this.authHandler({ user });
      }
    });

  }

  componentWillUnmount() {
    base.removeBinding(this.ref);
  }

  addWord(text, fromName, fromId) {
    const words = {...this.state.words};
    const id = Date.now();
    const word = { text, id, fromName, fromId };

    words.list = words.list || [];
    words.heads = words.heads || [];

    if (words.heads.indexOf(fromId) < 0) {
      words.heads.push(fromId);
    }

    words.list.push(word);
    words.lastWordFrom = fromId;

    this.setState({ words });
  }

  num2CapitalizedWord(number) {
    const word = toWords(number);
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  inputFocused() {
    this.setState({ inputFocused: true });
  }

  inputBlurred() {
    this.setState({ inputFocused: false });
  }

  updateInputText(inputText) {
    this.setState({ inputText });
  }

  clearInputText() {
    this.setState({ inputText: '' });
  }

  authHandler(authData) {
    const { user } = authData;

    this.setState({
      uid: user.uid,
      userName: user.displayName,
      userPhotoUrl: authData.user.photoURL,
    });
  }

  authenticate() {
    firebase.auth().signInWithPopup(provider).then(this.authHandler)
      .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
  }

  unauthenticate() {
    console.log('tryna log out')

    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      this.setState({
        uid: null,
        userName: null,
        userImgUrl: null,
      });
    }).catch(function(error) {
    });

  }

  renderLoggedIn(numHeads) {
    if (this.state.uid === this.state.words.lastWordFrom) {
      return this.renderWaitMessage(numHeads)
    }

    return this.renderInput()
  }

  renderWaitMessage(numHeads) {
    return (
      <p className="wait-message">
        According to the code of The Marvelous {numHeads}-headed beast,
        you must wait for another head to speak before speaking again.
      </p>
    )
  }

  renderInput() {
    return <WordInput
      addWord={this.addWord}
      inputText={this.state.inputText}
      inputFocused={this.inputFocused}
      inputBlurred={this.inputBlurred}
      updateInputText={this.updateInputText}
      clearInputText={this.clearInputText}
      userName={this.state.userName}
      uid={this.state.uid}
      lastWordFrom={this.state.words.lastWordFrom}
    />
  }

  render() {
    var formattedNumHeads;

    if (this.state.words &&
      this.state.words.heads &&
      this.state.words.heads.length > 0) {
      const heads = this.state.words.heads;
      formattedNumHeads = this.num2CapitalizedWord(heads.length);
    } else {
      formattedNumHeads = 'Multi';
    }

    return (
      <div>
        {this.state.uid &&
            <div className="logout-button">
              <img className="logout-button__img" src={this.state.userPhotoUrl} alt="" />
              <button onClick={this.unauthenticate} className="btn btn--x-lil">Log Out</button>
            </div>
        }
        <Intro numHeads={formattedNumHeads} />
        <div className="l-main" id="story">
          <p className="story-paragraph"><q><WordList
                wordList={this.state.words.list || []}
              /><InsertionPoint
                text={this.state.inputText}
                uid={this.state.uid}
                inputFocused={this.state.inputFocused}
              /></q></p>

        {this.state.userName ? this.renderLoggedIn(formattedNumHeads) : <LoginPrompt authenticate={this.authenticate} numHeads={formattedNumHeads} /> }
      </div>
      </div>
    );
  }
}

export default App;
