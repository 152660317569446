import React from 'react';
import PropTypes from 'prop-types';

class WordInput extends React.Component {
  constructor() {
    super();

    this.state = {
      inputValid: false,
      inputClass: '',
    }

    this.validate = this.validate.bind(this);

    this.animationTriggerClass = 'animation-target';
  }

  componentWillUnmount() {
    this.props.inputBlurred();
  }

  triggerAnimation() {
    this.setState({ inputClass: '' });
    setTimeout(() => {
      this.setState({ inputClass: this.animationTriggerClass });
    }, 80);
  }

  filterKeys(e) {
    // No spaces allowed!
    if (e.key === ' ') {
      this.triggerAnimation();

      e.preventDefault();
    }

    if (e.key === 'Enter' && !this.state.inputValid) {
      this.triggerAnimation();
    }
  }

  validate(value) {
    return (
      (value !== '') &&
      (this.props.lastWordFrom !== this.props.uid)
    )
  }

  handleChange(e) {
    const trimmedValue = e.target.value.trim();
    const inputValid = this.validate(trimmedValue);

    this.props.updateInputText(trimmedValue);
    this.setState({ inputValid });
  }

  submit(e) {
    e.preventDefault();

    if (this.state.inputValid) {
      this.props.addWord(this.props.inputText, this.props.userName, this.props.uid);
      this.form.reset();
      this.props.clearInputText();
    } else {
      this.triggerAnimation();
    }
  }

  render() {
    const disableButton = !this.state.inputValid;

    return (
      <div className="word-input">
        <form className="word-input__form"
          onSubmit={this.submit.bind(this)}
          ref={(form) => this.form = form}
        >
          <label className="word-input__label">
            <input className={`word-input__input ${this.state.inputClass}`}
              onKeyPress={this.filterKeys.bind(this)}
              onChange={this.handleChange.bind(this)}
              autoCapitalize="off"
              onFocus={this.props.inputFocused}
              onBlur={this.props.inputBlurred}
            />
            <span className="word-input__text">Next Word</span>
          </label>
          <button className="btn btn--lil word-input__button" type="submit" disabled={disableButton}>Add</button>
        </form>
      </div>
    )
  }
}

WordInput.propTypes = {
  addWord: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  inputText: PropTypes.string.isRequired,
};

export default WordInput;
