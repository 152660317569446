import React from 'react';
import PropTypes from 'prop-types';

class InsertionPoint extends React.Component {
  debugger
  render() {
    if (this.props.uid) {
      if (this.props.inputFocused) {
        return (
          <span className="insertion-point insertion-point--is-inserting">{this.props.text}<span className="insertion-point__cursor"></span></span>
        )
      }
      return <span className="insertion-point">&hellip;</span>;
    }
    return <span className="insertion-point">&mdash;</span>;
  }
}

InsertionPoint.propTypes = {
  inputFocused: PropTypes.bool.isRequired,
  uid: PropTypes.string,
};

export default InsertionPoint;
