import React from 'react';
// import PropTypes from 'prop-types';
import BeastWords from './BeastWords';
import beastImg from '../img/the-marvelous-n-headed-beast.jpg';

function Intro(props) {
  const { numHeads } =  props;
  return (
    <div className="l-main">
      <div className="intro">
        <img className="intro__a" src={beastImg} alt={`Illustration of The Marvelous ${numHeads}-headed Beast`}/>
        <div className="intro__b">

          <p>
            <BeastWords words="Hello, my child," />
             &nbsp;spake The Marvelous {numHeads}-headed Beast.
          </p>

          <p>
            <BeastWords words="Lend me your ear to hear my tale and I will not eat you today," />
            &nbsp;whispered the heads.
          </p>

          <p>
            Reluctantly, you agree.
          </p>

        </div>
      </div>
    </div>
  )
}

// Intro.propTypes = {
  // numHeads: PropTypes.number.isRequired,
// }

export default Intro;
